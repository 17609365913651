import { LoadingComponent } from './../../../utils/dialogs/loading/loading.component';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { CrudService } from 'src/app/core/services/crud/crud.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService extends CrudService {
  
  dialogLoading: MatDialogRef<LoadingComponent>

  url = environment.apiUrl;
  endpoint = 'categories';

  constructor(http: HttpClient, dialog: MatDialog) {
    super(http, dialog);
  }
}
