// import { PermissionGuard } from './../auth/guard/permission.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NavigationComponent } from './navigation.component';

const routes: Routes = [
  {
    path: '',
    component: NavigationComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
        import('../store/store.module').then(
          m => m.StoreModule,
          ),
      },
      {
        path: 'cliente',
        loadChildren: () =>
        import('../client/client.module').then(
          m => m.ClientModule,
          ),
      },
      {
        path: 'venda-conosco',
        loadChildren: () =>
        import('../sale-with-us/sale-with-us.module').then(
          m => m.SaleWithUsModule,
          ),
      },
      {
        path: 'atendimento-ao-cliente',
        loadChildren: () =>
        import('../../utils/pages/customer-service/customer-service.module').then(
          m => m.CustomerServiceModule,
          ),
      },
      { path: 'faq', loadChildren: () => import('../../utils/pages/faq/faq.module').then(m => m.FaqModule) },
      { path: 'chat', loadChildren: () => import('../chat/chat.module').then(m => m.ChatModule) },

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NavigationRoutingModule { }
