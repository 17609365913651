<mat-toolbar [@toggle]="isVisible ? 'visible' : 'hidden'" color="primary" class="d-flex justify-content-center align-items-center navbar-main d-md-none d-lg-block">
  <div class="row w-100 d-flex align-items-center justify-content-end">

    <!-- ======= LOGO  ======= -->
    <div class="col-2 p-0 d-flex align-items-center justify-content-start">
      <a [routerLink]="['/']">
        <img  src="../../../assets/images/Sìmbolo-rosa.png" width="50px"/>
      </a>
    </div>

    <!-- ======= SEARCH  ======= -->
    <div class="col-7 text-left p-0 m-0">
      <form [formGroup]="formSearch">
        <div class="input-group">
          <input class="input-search form-control" formControlName="searchTerm" placeholder="Buscar"
            (keydown.enter)="search()">
          <div class="input-group-append">
            <button class="btn w-auto btn-search py-0 d-flex justify-content-center align-items-center" (click)="search()">
              <mat-icon color="primary" class="p-0">search</mat-icon>
            </button>
          </div>
        </div>
      </form>
    </div>

    <!-- ======= PROFILE  ======= -->
    <div class="col-3 text-left p-0 m-0 d-flex align-items-center justify-content-end">
      <button mat-icon-button  (click)="sidenav.toggle()" class="icon-cart">
        <mat-icon>menu</mat-icon>
      </button>
      <button mat-icon-button [routerLink]="['/carrinho']" class="icon-cart">
        <mat-icon svgIcon="carts" [matBadge]="cartLenght" matBadgeColor="warn" [matBadgeHidden]="cartLenght == 0"></mat-icon>
      </button>
    </div>

  </div>
</mat-toolbar>

