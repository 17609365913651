import { SubcategoriesService } from './../../core/services/subcategories/subcategories.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoriesService } from 'src/app/core/services/categories/categories.service';

@Component({
  selector: 'app-list-subcategory',
  templateUrl: './list-subcategory.component.html',
  styleUrls: ['./list-subcategory.component.scss']
})
export class ListSubcategoryComponent implements OnInit {

  @Input() selectCategory = null;
  @Input() bottomSheet: boolean = false;
  @Output() emitSubcategory = new EventEmitter();
  @Input() subcategoriesList = [];

  subcategoryAllList = [];
  showAllSubcategories = false;


  categoryTerms: any[] = [];
  subcategoriesTerms: any[] = [];
  brandTerms: any[] = [];
  priceFromTerms: any[];
  priceToTerms: any[];
  orderByTerms: any[];
  // priceRange: any;
  termsQueries: any[] = [
    {
      term: 'category',
      varValue: 'categoryTerms'
    },
    {
      term: 'subcategory',
      varValue: 'subcategoriesTerms'
    },
    {
      term: 'brands',
      varValue: 'brandTerms'
    },
    // {
    //   term: 'priceRange',
    //   varValue: 'priceRange'
    // },
    {
      term: 'price_from',
      varValue: 'priceFromTerms'
    },
    {
      term: 'price_to',
      varValue: 'priceToTerms'
    },
    {
      term: 'order_by_price',
      varValue: 'orderByTerms'
    },

  ]

  innerWidth: number;
  search: any;
  subcategories: any[] = [];
  categoriesObjArray: any[] = [];
  arraySubcategories: any[] = [];

  constructor(
    private router: Router, private activatedRoute: ActivatedRoute,
    private subcategoriesService: SubcategoriesService,
    private categoriesService: CategoriesService,
  ) {
    this.innerWidth = window.innerWidth;
    this.activatedRoute.queryParams.subscribe(data => {
      if(data['term']){
        this.search = data['term']
      }
      this.termsQueries.forEach(termsQuery => {
        this.getQuery( data[termsQuery.term ] , termsQuery);
      })
    })
    this.categoryTerms.forEach(item => {

      this.getCategory(item);
    })
  }

  getCategory(category_id: string) {

    if(!this.categoriesObjArray.some(item => item.id === category_id)){

      this.categoriesService.getById(category_id).then( res => {

        this.categoriesObjArray.push(res);
        this.setArraySubcategories();
      }).catch(error => {

      })
    } else {
      this.setArraySubcategories();
    }
  }
  setArraySubcategories(){
    let array = []
    this.categoriesObjArray.forEach(item => {
      array = array.concat(item.subcategories)
    } )

    this.arraySubcategories = array;
  }

  ngOnInit(): void {
    // this.getSubcategories();
  }

  ngOnChanges(){
    console.log(this.subcategoriesList);
    if(this.subcategoriesList?.length >= 0){
      this.subcategories= this.subcategoriesList.slice(0, 5)
    } else{
      this.subcategories = this.subcategoryAllList.slice( 0, 5 );
    }


    // this.subcategoryAllList = this.subcategoryAllList;
    // this.subcategories = this.subcategoryAllList.slice( 0, 5 );

  }

  // getArray(){
  //   if(this.categoryTerms.length === 0 ){
  //     return  this.subcategories;
  //   }else {

  //     return this.arraySubcategories.filter(item => this.categoryTerms.includes(item.category_id));
  //   }
  // }

  getQuery( item, termsQuery  ){
    if(!item){
      this[termsQuery.varValue] = []
    } else {
      this[termsQuery.varValue] = Array.isArray(item)? item: [item];
    }


  }


  getSubcategories(){
    this.subcategoriesService.getAll().then(res => {
      // this.subcategories = res;

      this.subcategoryAllList = res;
      this.subcategories = this.subcategoryAllList.slice( 0, 5 );
    }).catch(error => {

    })
  }

  ngAfterViewInit(){
    this.scrollInit();
  }

  scrollInit() {
    setTimeout(() => {
     const element: HTMLElement = document.getElementById('start');
     element.scrollIntoView({ behavior:"smooth" });
    }, 100)
   }

  searchSubcategory(param) {

    this.emitSubcategory.emit(param);

    setTimeout(() => {

      let queryParams = {}
      if(this.search){
        queryParams['term'] = this.search;
      }
      if( this.categoryTerms.length !== 0){
        queryParams['category'] = this.categoryTerms
      }
      if(this.subcategoriesTerms.length !== 0){
        queryParams['subcategory'] = this.subcategoriesTerms;
      }
      if( this.brandTerms.length !== 0){
        queryParams['brands'] = this.brandTerms
      }
      // if( this.priceRange){
      //   queryParams['priceRange'] = this.priceRange
      // }
      if( this.priceFromTerms.length !== 0 ){
        queryParams['price_from'] = this.priceFromTerms
      }
      if( this.priceToTerms.length !== 0 ){
        queryParams['price_to'] = this.priceToTerms
      }
      if( this.orderByTerms.length !== 0 ){
        queryParams['order_by_price'] = this.orderByTerms
      }

      this.router.navigate(['/busca'] , {
        queryParams
      });
    }, 500);
  }

  seeAllSubcategories() {
    this.showAllSubcategories = !this.showAllSubcategories;
    if(this.showAllSubcategories) {
      this.subcategories = this.subcategoriesList;
    } else {
      this.subcategories = this.subcategoriesList.slice( 0, 5 );
    }
  }

}
