import { InformationComponent } from './../../../utils/dialogs/information/information.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from './../auth.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dialog-login',
  templateUrl: './dialog-login.component.html',
  styleUrls: ['./dialog-login.component.scss']
})
export class DialogLoginComponent implements OnInit {

  dialogInformation: MatDialogRef<InformationComponent>;

  formLogin = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]]

  });

  hide: boolean;
  error: boolean;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.hide = true;
    this.error = false;
  }

  // login() {
  //   this.authService.login(this.formLogin.value).then(res => {
  //     let url;
  //     url = res;
  //     this.navigateTo(url);
  //   }).catch(error => {
  //     if (error.status == 401) {
  //       this.dialogInformation = this.dialog.open(InformationComponent, {
  //         disableClose: true,
  //         data: {
  //           error: true,
  //           message: 'Usuário ou senha incorretos!'
  //         }

  //       })
  //     }
  //   });

  // }



  // public navigateTo(url?: string) {
  //   url = url || '';
  //   this.router.navigate([url], { replaceUrl: true });
  // }
}
