<div class="check d-flex flex-column align-items-center justify-content-center">
  <img src="/assets/images/Logo-cor.png" class="img-logo" alt="logo">
  <h3 *ngIf="!checkChange" mat-dialog-title class="mt-3" style="font-size: 1.5rem; font-weight: bold;">Você tem mais de 18 anos?</h3>
  <h4 *ngIf="checkChange" class="mt-3 text-center" style="font-size: 1.1rem; font-weight: bold;">eubebo comercializa bebidas alcoólicas e não alcoólicas</h4>
  <h4 *ngIf="checkChange" class="text-center" style="font-size: 1.1rem; font-weight: bold;">No entanto, a venda de bebidas alcoólicas é permitida apenas para maiores de 18 anos, você está ciente disso?</h4>
</div>
<div *ngIf="!checkChange" class="d-flex align-items-center justify-content-center"mat-dialog-actions>
  <button mat-raised-button color="primary" class="m-2" (click)="confirmOlder()">Sim</button>
  <button mat-raised-button color="warn" class="m-2" (click)="recuseOlder()">Não</button>
</div>
<div *ngIf="checkChange" class="d-flex align-items-center justify-content-center"mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="confirmOlder()" class="m-2">Sim, concordo</button>
  <button mat-raised-button color="warn" (click)="recuseOlder()" class="m-2">Cancelar</button>
</div>
