import { InformationComponent } from './../../../utils/dialogs/information/information.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from './../auth.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoadingComponent } from 'src/app/utils/dialogs/loading/loading.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @Input() isDialog = false
  dialogInformation: MatDialogRef<InformationComponent>;

  // openLoading = this.dialog.open(LoadingComponent, { disableClose: true, panelClass: 'loading-dialog-container' });
  // closeLoading = setTimeout(() => { this.openLoading.close() }, 1500);

  formLogin = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]]
  });

  hide: boolean;
  error: boolean;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.hide = true;
    this.error = false;
  }

  login() {
    // this.openLoading;
    this.authService.login(this.formLogin.value).then(res => {
      let url;
      url = res;
      this.dialog.closeAll();
      this.navigateTo(url);
    }).catch(error => {
      if (error.status == 401) {
        this.dialogInformation = this.dialog.open(InformationComponent, {
          disableClose: true,
          data: {
            error: true,
            message: error.error.message
          }
        })
      }
    }).finally(() => {
      // this.closeLoading;
    });

  }

  register(){
    this.router.navigate(['/autenticacao/cadastrar']);
    this.dialog.closeAll();
  }

  forgot(){
    this.router.navigate(['/autenticacao/recuperar-senha']);
    this.dialog.closeAll();
  }

  public navigateTo(url?: string) {
    url = url || '';
    if(this.router.url.includes('entrar')){
      this.router.navigate([url], { replaceUrl: true });
    } else {
      window.location.reload();
    }
  }

}
