import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SaleWithUsComponent } from './sale-with-us.component';


const routes: Routes = [
  {
    path: '',
    component: SaleWithUsComponent,

  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class SaleWithUsRoutingModule { }
