import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListSubcategoryComponent } from './list-subcategory.component';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';



@NgModule({
  declarations: [ListSubcategoryComponent],
  imports: [
    CommonModule,
    MatListModule,
    RouterModule,
    FormsModule,
    MatDividerModule,
  ],
  exports: [
    ListSubcategoryComponent,

  ]
})
export class ListSubcategoryModule { }
