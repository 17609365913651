import { NavigationRoutingModule } from './../../../pages/navigation/navigation-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotFoundComponent } from './not-found.component';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { LottieModule } from 'ngx-lottie';

@NgModule({
  declarations: [
    NotFoundComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    NavigationRoutingModule,
    RouterModule,
    LottieModule,
  ]
})
export class NotFoundModule { }
