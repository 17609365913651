import { HttpErrorResponse, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError, filter, finalize, retry } from 'rxjs/operators';
import { AuthService } from 'src/app/pages/auth/auth.service';
import { LoadingComponent } from 'src/app/utils/dialogs/loading/loading.component';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor  {

  dialogLoading: MatDialogRef<LoadingComponent>;


  constructor(private authService: AuthService, private router: Router, private dialog: MatDialog) { }

  intercept(req: HttpRequest<any>, next: HttpHandler, ){

    if(req.url.includes('http')){
      if(!this.dialogLoading){
        this.dialogLoading = this.dialog.open(LoadingComponent, { disableClose: true, panelClass: 'loading-dialog-container' })

      }

    }
    return next.handle(req).pipe(

      finalize(()=> {
        if(req.url.includes('http')){
          setTimeout(() => {

            if(this.dialogLoading){
              this.dialogLoading.close();
              this.dialogLoading = null;
            }

          }, 500);

        }

      }),
      catchError((error: HttpErrorResponse)=> {

        // if(error.status === 401){
        //   this.authService.logout();

        //   this.router.navigate(['/']);
        // }
        
        return throwError(error);
      })


    );
  }

}
