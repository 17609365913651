import { Component, OnInit } from '@angular/core';
import { PaymentMethodsService } from 'src/app/core/services/payment-methods/payment-methods.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  cardFlags: any[] = []

  constructor(
    private paymentMethodsService: PaymentMethodsService
  ) { }

  ngOnInit() {
    this.getPaymentFlags();
  }

  getPaymentFlags(){
    this.paymentMethodsService.getAll().then((types: any) => {
      
      types.forEach(type => {
        type.brands.forEach(brand => {
          if(!this.cardFlags.map(res => res.name).includes(brand.name))
            this.cardFlags.push(brand)
        })
      });
    })
  }

}
