import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CrudService } from '../crud/crud.service';

@Injectable({
  providedIn: 'root'
})
export class CustomersService extends CrudService {


  url = environment.apiUrl;
  endpoint = 'customers';

  constructor(http: HttpClient, dialog: MatDialog) {
    super(http, dialog);
  }

  public async forgot(body): Promise<any> {
    let response = null;
    try {
      response = await this.http
        .post(this.url + '/' + this.endpoint + '/' + 'forgot', body)
        .toPromise();
    } catch (error) {
      response = this.errorHandler('POST', error);
    }
    return response;
  };

  public async resetPassword(body): Promise<any> {
    let response = null;
    try {
      response = await this.http
        .post(this.url + '/' + this.endpoint + '/' + 'reset', body)
        .toPromise();
    } catch (error) {
      response = this.errorHandler('POST', error);
    }
    return response;
  };

  createCard(userId: string, customerCard:any): Promise<any> {
    return this.http.post(`${this.url}/${this.endpoint}/${userId}/cards`, customerCard).toPromise()
  }

  createAddress(userId: string, customerAddress: any) : Promise <any> {
    return this.http.post(`${this.url}/${this.endpoint}/${userId}/addresses`, customerAddress ).toPromise();
  }

  deleteAddress(userId: string, customerAddressId: any): Promise <any> {
    return this.http.delete(`${this.url}/${this.endpoint}/${userId}/addresses/${customerAddressId}`).toPromise();
  }

  updateAddress(userId: string, customerAddressId: string , address: any): Promise <any> {
    return this.http.put(`${this.url}/${this.endpoint}/${userId}/addresses/${customerAddressId}`, address).toPromise();
  }


  deleteCard(userId: string, card_id: string ): Promise <any> {
    return this.http.delete(`${this.url}/${this.endpoint}/${userId}/cards/${card_id}`).toPromise();
  }

  createFavorite(userId: string, productId: any) : Promise <any> {
    return this.http.post(`${this.url}/${this.endpoint}/${userId}/favorites`, productId ).toPromise();
  }

  deleteFavorite(userId: string, favoriteProductId: any): Promise <any> {
    return this.http.delete(`${this.url}/${this.endpoint}/${userId}/favorites/${favoriteProductId}`).toPromise();
  }


  public errorHandler(
    method: string,
    error: HttpErrorResponse,
  ): Promise<never> {
    console.error(
      `Error occurred during ${method} ${this.url + this.endpoint}`,
      error,
    );
    return Promise.reject(error);
  }



}
