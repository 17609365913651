import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SellWithUsContactService } from 'src/app/core/services/sell-with-us-contact/sell-with-us-contact.service';
import { TitleInMetaService } from 'src/app/core/services/title-in-meta/title-in-meta.service';
import { InformationComponent } from 'src/app/utils/dialogs/information/information.component';
import { LoadingComponent } from 'src/app/utils/dialogs/loading/loading.component';
import { AuthService } from '../auth/auth.service';

@Component({
  templateUrl: './sale-with-us.component.html',
  styleUrls: ['./sale-with-us.component.scss']
})
export class SaleWithUsComponent implements OnInit {

  dialogInformation: MatDialogRef<InformationComponent>;

  // openLoading = this.dialog.open(LoadingComponent, { disableClose: true, panelClass: 'loading-dialog-container' });
  // closeLoading = setTimeout(() => { this.openLoading.close() }, 1500);

  form = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    responsable_name: ['', [Validators.required]],
    phone_number: ['',[Validators.required]]
  });

  hide: boolean;
  error: boolean;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private dialog: MatDialog,
    private sellWithUsContactService: SellWithUsContactService,
    private titleInMetaService: TitleInMetaService,
  ) {
    this.setTitleInMetas('eubebo - Venda com a gente');
   }

  ngOnInit(): void {
    this.hide = true;
    this.error = false;
  }

  setTitleInMetas(newTitle) {
    this.titleInMetaService.updateTitle(newTitle);
  }

  ngAfterViewInit(){
    this.scrollInit();
  }

  scrollInit() {
   setTimeout(() => {
    const element: HTMLElement = document.getElementById('start');
    element.scrollIntoView({ behavior:"smooth" });
   }, 100)
  }

  register(){
    this.router.navigate(['/autenticacao/cadastrar']);
    this.dialog.closeAll();
  }

  forgot(){
    this.router.navigate(['/autenticacao/recuperar-senha']);
    this.dialog.closeAll();
  }

  public navigateTo(url?: string) {
    url = url || '';
    this.router.navigate([url], { replaceUrl: true });
  }

  submit(){

    if(this.form.invalid){
      this.form.markAllAsTouched();
      return;
    }
    const sellWithUsContact = this.form.value;
    sellWithUsContact.phone_number = this.form.controls.phone_number.value.replace(/[()\- ]/gi, '');
    this.sellWithUsContactService.post(sellWithUsContact).then(res => {
      
      this.dialogInformation = this.dialog.open(InformationComponent, {
        panelClass: 'container-add',
        disableClose: true,
        data: {
          error: false,
          message: 'Cadastro enviado entraremos em contato.'
        }
      })
    }).catch(error => {
      this.dialogInformation = this.dialog.open(InformationComponent, {
        panelClass: 'container-add',
        disableClose: true,
        data: {
          error: true,
          message: error.error.message
        }
      })
    })
  }

}
