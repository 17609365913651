<mat-sidenav-container class="sidenav-container example-sidenav-container mb-2">

  <!-- ======= SIDENAV MOBILE ======= -->
  <mat-sidenav class="example-sidenav" [(opened)]="openSidenav" #sidenav [mode]="'over'">


    <div class="d-flex align-items-end justify-content-end">
      <div class="w-75 d-flex align-items-center justify-content-center mt-3" style="font-size: 1.5rem;">
        <p *ngIf="isLogged" > Olá, <strong>{{clientName}}</strong></p>
        <p *ngIf="!isLogged" > Olá, faça seu login</p>
      </div>
      <div style="width: 50px; background-color: #fff; ">
        <button mat-icon-button (click)="sidenav.toggle()" >
          <mat-icon>menu_open</mat-icon>
        </button>
      </div>
    </div>
    <mat-divider></mat-divider>

    <mat-selection-list  [multiple]="false"  >

      <mat-list-option [disableRipple]="true"  class=" button-transparent user-menu w-100" (click)="login()" [hidden]="isLogged">
        <mat-icon mat-list-icon svgIcon="login" ></mat-icon>
        Entrar
      </mat-list-option>

      <mat-list-option [disableRipple]="true"  class=" button-transparent user-menu w-100" (click)="openSidenav = false" [routerLink]="['/autenticacao/cadastrar']" [hidden]="isLogged">
        <mat-icon mat-list-icon svgIcon="add-user" ></mat-icon>
        Criar minha conta
      </mat-list-option>

      <mat-list-option [disableRipple]="true"  class=" button-transparent user-menu w-100" (click)="openSidenav = false" [routerLink]="['/']" [hidden]="!isLogged">
        <mat-icon mat-list-icon svgIcon="home" ></mat-icon>
        Página inicial
      </mat-list-option>

      <mat-list-option [disableRipple]="true"  class=" button-transparent user-menu w-100" (click)="openSidenav = false" [routerLink]="['/cliente/pedidos']" [hidden]="!isLogged">
        <mat-icon mat-list-icon svgIcon="invoice"></mat-icon>
        Pedidos
      </mat-list-option>

      <mat-list-option [disableRipple]="true"  class=" button-transparent user-menu w-100" (click)="openSidenav = false" [routerLink]="['/cliente/metodos-de-pagamento']" [hidden]="!isLogged">
        <mat-icon mat-list-icon svgIcon="wallet"></mat-icon>
        Métodos de pagamento
      </mat-list-option>

      <mat-list-option [disableRipple]="true"  class=" button-transparent user-menu w-100" (click)="openSidenav = false" [routerLink]="['/cliente/endereco']" [hidden]="!isLogged">
        <mat-icon mat-list-icon svgIcon="map"></mat-icon>
        Endereços
      </mat-list-option>

      <mat-list-option [disableRipple]="true"  class=" button-transparent user-menu w-100" (click)="openSidenav = false" [routerLink]="['/cliente/favoritos']" [hidden]="!isLogged">
        <mat-icon mat-list-icon svgIcon="heart"></mat-icon>
        Favoritos
      </mat-list-option>

      <mat-list-option [disableRipple]="true"  class=" button-transparent user-menu w-100" [matMenuTriggerFor]="menu" [hidden]="!isLogged">
        <mat-icon mat-list-icon svgIcon="user"></mat-icon>
        Minha conta
      </mat-list-option>

      <mat-divider class="my-3"></mat-divider>

      <mat-list-option [disableRipple]="true"  class=" button-transparent user-menu w-100" (click)="openSidenav = false" [routerLink]="['/venda-conosco']"   >
        <mat-icon mat-list-icon svgIcon="united" ></mat-icon>
        <span>Venda com a gente</span>
      </mat-list-option>

      <mat-list-option [disableRipple]="true"   class=" button-transparent user-menu w-100" (click)="openSidenav = false" [routerLink]="['/atendimento-ao-cliente']" >
        <mat-icon mat-list-icon svgIcon="call-center"></mat-icon>
        <span>Atendimento ao cliente</span>
      </mat-list-option>

      <mat-list-option [disableRipple]="true"   class=" button-transparent user-menu w-100" (click)="openSidenav = false" [routerLink]="['/faq']" >
        <mat-icon mat-list-icon svgIcon="faq"></mat-icon>
        <span>Faq</span>
      </mat-list-option>

    </mat-selection-list>

  </mat-sidenav>


  <mat-sidenav-content class="container-conteudo" (scroll)="scroll($event)">
    <div id="start"></div>

    <!-- ======= TOOLBAR CUPOM ======= -->
    <mat-toolbar *ngIf="innerWidth >= 576" style="flex-direction: column; flex-wrap: wrap; height: 40px; min-height: 40px;" class=" align-items-center justify-content-center px-2 py-4 ">

      <!-- ======= MODAL OVERLAY ======= -->
      <div *ngIf="panelOpenState || panelSubOpenState" class="modal-overlay"></div>

      <div class="d-flex justify-content-center align-items-center text-center ">
        <p class="my-1 text-cupom">
          Ganhe 10% de desconto no seu primeiro pedido com o cupom 
          <span style="border: 1px dashed #ccc; color: #eb0045; text-transform:uppercase" class="p-1"> eubebo10</span> 
        </p>
      </div>
    </mat-toolbar>

    <!-- ======= NAVBAR MOBILE ======= -->
    <app-sticky-header *ngIf="innerWidth < 576" [sidenav]="sidenav" [isVisible]="isVisible" ></app-sticky-header>

    <!-- ======= NAVBAR DESKTOP ======= -->
    <mat-toolbar [@toggle]="isVisible ? 'visible' : 'hidden'" *ngIf="innerWidth >= 576" color="primary" class=" justify-content-center align-items-center navbar-main">

      <!-- ======= MODAL OVERLAY ======= -->
      <div *ngIf="panelOpenState || panelSubOpenState" class="modal-overlay"></div>
      
      <div class="row w-100 d-flex align-items-center justify-content-between container">

        <!-- ======= LOGO  ======= -->
        <div class="p-0 mb-2 mb-md-0 d-flex align-items-center justify-content-start order-1 col-2 col-md-1 col-lg-3">
          <a [routerLink]="['/']">
            <img *ngIf="innerWidth >= 992 || innerWidth < 768" src="/assets/images/Logo fundo roxo.png" width="200px" style="min-height: 7mm;"/>
            <img *ngIf="innerWidth < 992 && innerWidth >= 768" src="/assets/images/Sìmbolo-rosa.png" width="50px"/>
          </a>
        </div>

        <!-- ======= SEARCH  ======= -->
        <div class="text-left p-0 m-0 order-3 order-md-2 col-12 col-md-6 col-lg-5">
          <form [formGroup]="formSearch">
            <div class="w-100 input-group">
              <input class="form-control input-search w-75" formControlName="searchTerm" placeholder="Buscar"
              (keydown.enter)="search()">
              <div class="input-group-append">
                <button class="btn w-auto btn-search py-0 d-flex justify-content-center align-items-center" (click)="search()" >   <mat-icon color="primary" class="p-0">search</mat-icon> </button>
              </div>
            </div>
          </form>
        </div>

        <!-- ======= MENU  ======= -->
        <div class="text-right p-0 m-0 order-2 order-md-3 col-10 col-md-4 col-lg-4">
          <button [matMenuTriggerFor]="menuAccount" class="w-auto pl-0 ml-0 button-transparent" style="color: #fff;">
            <span class="d-flex align-items-center align-content-center justify-content-center ">
              <mat-icon style="margin-bottom: 12px;" mat-list-icon svgIcon="user"></mat-icon>
              <span class=" p-0 m-0 ml-1">  {{isLogged ? 'Olá, ' + clientName : 'Olá, faça seu login'}} </span>
            </span>
          </button>
          <button style="background-color: transparent !important; border: none; border-radius:50%; height: 100%; width: auto" [routerLink]="['/carrinho']" (mouseenter)="shoppingCartOpen()" (mouseleave)="shoppingCartClose()" >
            <mat-icon svgIcon="carts" color="accent" [matBadge]="cartLenght" matBadgeColor="warn" [matBadgeHidden]="cartLenght == 0"></mat-icon>
          </button>
        </div>

      </div>
    </mat-toolbar>

    <!-- ======= MENU DESKTOP ======= -->
    <mat-toolbar [@toggle]="isVisible ? 'visible' : 'hidden'" *ngIf="innerWidth >= 576" style="flex-direction: column; flex-wrap: wrap;" class="align-items-start justify-content-center navbar-category px-2 py-4">
       
      <div #menuBar class="d-flex align-items-center justify-content-between container p-0">
      
        <!-- ======== MENU ======== -->
        <ul class="menu-desktop order-2">
        <li *ngFor="let category of categoryList; let i = index" class="menu-item" id="{{'menu-item-' + i}}">
          <a class="nav-link-desktop" (click)="searchCategory(category)" (mouseover)="showDropDown(i)" (mouseleave)="removeDropdown(i)">
            {{category.name}}
          </a>
          <ul *ngIf="innerWidth >= 992" class="dropdown-menu" id="{{'dropdown-item-' + i}}" 
            (mouseover)="statusDropDown = true" (mouseleave)="removeDropdown(i)">
            <li *ngFor="let subcategory of category.subcategories.slice(0,5)" class="dropdown-item">
              <a (click)="searchSubcategory(subcategory)" class="nav-link-dropdown">{{subcategory.name}}</a>
            </li>
            <li class="dropdown-item">
              <a (click)="searchCategory(category)" class="nav-link-dropdown" style="color: #510c76">Ver mais</a>
            </li>
          </ul>
        </li>
        </ul>

        <!-- ======== BUTTON ADDRESS ======== -->
        <a *ngIf="innerWidth >= 992" class="w-auto mat-elevation-z8 order-3" mat-raised-button style="border-radius: 10px 0 10px 10px ;"  color="primary" [href]="urlDelivery" target="_blank" >
          <span ><mat-icon class="mx-2"  mat-list-icon svgIcon="delivery"></mat-icon> Delivery </span>
        </a>

        <!-- ======== BUTTON ADDRESS ======== -->
        <button *ngIf="innerWidth >= 992" class="w-auto mat-elevation-z8 order-3" mat-raised-button style="border-radius: 10px 0 10px 10px ;"  color="primary"  (click)="changeAddress()">
          <span *ngIf="zipCode" ><mat-icon class="mx-2"  mat-list-icon svgIcon="map"></mat-icon> {{ addressValue |truncate: ['25', '...']  }} </span>
          <span *ngIf="!zipCode" ><mat-icon class="mx-2"  mat-list-icon svgIcon="map"></mat-icon> Informe seu CEP </span>
        </button>
        
        <!-- ======== BUY CATEGORY ======== -->
        <div *ngIf="innerWidth >= 1200 && !loading" class="order-1" >
 
           <!-- ======== BUTTON BUY CATEGORY ======== -->
           <button class="buy-category" [ngClass]="{'arrow-open' : panelOpenState}"
             [style]="'height:' + menuBar.offsetHeight + 'px;width:' + panelCategory.offsetWidth + 'px'"
             (click)="this.panelOpenState=!this.panelOpenState;this.panelSubOpenState=false;" (mouseover)="awaitDropDown()"
             (mouseleave)="panelOpenState=false;panelSubOpenState=false;statusAwaitDropDown=false">
             <mat-icon class="mr-2" style="color: #510c76">menu</mat-icon>
             Compre por categoria
           </button>
 
           <!-- ======== DROPDOWN PANEL 1 ======== -->
           <ul #panelCategory class="dropdown-list-categories" [ngClass]="{'active-dropdown-menu' : panelOpenState}"
             (mouseover)="panelOpenState=true;panelSubOpenState=true" (mouseleave)="removeDropdownCategory()">
             <li *ngFor="let category of buyCategoriesList" 
               class="dropdown-item-categories" (click)="searchCategory(category)" 
               (mouseover)="changeCategoryDropDown(category.id, category.name, category.subcategories);">
               <a class="item-buy-categories">{{category.name}}</a>
               <mat-icon *ngIf="category.subcategories.length > 0" class="mr-2">chevron_right</mat-icon>
             </li>
             <li id="item-all-categories" class="dropdown-item-categories" (click)="searchAllCategories()"
               (mouseover)="changeCategoryDropDown(null, 'Todas as categorias', categoriesAllList)">
               <a class="item-buy-categories">Todas as categorias</a>
               <mat-icon class="mr-2">chevron_right</mat-icon>
             </li>
           </ul>
 
           <!-- ======== DROPDOWN PANEL 2 ======== -->
           <div *ngIf="panelSubOpenState && categoryDropDown.subtitles.length > 0" class="dropdown-subcategory" 
             [style]="'height:' + (panelCategory.offsetHeight + menuBar.offsetHeight) + 'px;max-width:' + (1140 - panelCategory.offsetWidth) + 'px;transform: translate(' + panelCategory.offsetWidth +'px,-' + menuBar.offsetHeight + 'px);'"
             (mouseover)="panelOpenState=true;" (mouseleave)="panelOpenState=false;panelSubOpenState=false;statusAwaitDropDown=false">
             <h2 (click)="searchCategory(categoryDropDown)" class="buy-link-cat-dropdown">{{categoryDropDown.title}}</h2>
             <ul class="panel-buy-subcategory">
              <li *ngFor="let subtitle of categoryDropDown.subtitles">
                <a *ngIf="categoryDropDown.id !== null" (click)="searchSubcategory(subtitle)" class="buy-link-sub-dropdown">{{subtitle.name}}</a>
                <a *ngIf="categoryDropDown.id === null" (click)="searchCategory(subtitle)" class="buy-link-sub-dropdown">{{subtitle.name}}</a>
              </li>
             </ul>
           </div>
 
        </div>

        <!-- ======= MODAL OVERLAY ======= -->
        <div *ngIf="panelOpenState || panelSubOpenState" class="modal-overlay"></div>

      </div>

    </mat-toolbar>

    <!-- ======= TOOLBAR ADDRESS MOBILE ======= -->
    <mat-toolbar *ngIf="innerWidth < 576" color="accent" class=" d-flex align-items-center justify-content-end ">

      <button mat-raised-button style="border-radius: 10px 0 10px 10px ;"  color="accent"  (click)="changeAddress()" class="mr-2 w-auto mat-elevation-z8">
        <span *ngIf="zipCode" ><mat-icon class="mx-2"  mat-list-icon svgIcon="map"></mat-icon> {{ addressValue |truncate: ['25', '...']  }} </span>
        <span *ngIf="!zipCode" ><mat-icon class="mx-2"  mat-list-icon svgIcon="map"></mat-icon> Informe seu CEP </span>
      </button>

    </mat-toolbar>

    <router-outlet></router-outlet>

    <!-- ======= FOOTER ======= -->
    <app-footer></app-footer>

  </mat-sidenav-content>
</mat-sidenav-container>

<!-- ======= MENU ACCOUNT ======= -->
<mat-menu #menuAccount="matMenu" xPosition="before" class="custom d-flex justify-content-start" >
  <div class="w-100 d-flex align-items-center justify-content-center mt-1 px-3 pt-3" style="font-size: 1.5rem;">
    <!-- <p *ngIf="isLogged" > Olá, <strong>{{clientName}}</strong></p>
    <p *ngIf="!isLogged" style="font-size: 1.15rem;" class="text-center" > Olá, faça seu login ou cadastre-se</p> -->
    <p>{{isLogged?'Minha conta': 'Acesse sua conta'}}</p>
  </div>
  <button  class=" button-transparent user-menu w-100" mat-menu-item [disableRipple]="true"  (click)="login()" [hidden]="isLogged">
    <mat-icon mat-list-icon svgIcon="login" ></mat-icon>
    Entrar
  </button>
  <button class=" button-transparent user-menu w-100" mat-menu-item [disableRipple]="true"  [routerLink]="['/autenticacao/cadastrar']" [hidden]="isLogged">
    <mat-icon mat-list-icon svgIcon="add-user" ></mat-icon>
    Criar minha conta
  </button>
  <button class=" button-transparent user-menu w-100" mat-menu-item [disableRipple]="true"  [routerLink]="['/cliente/pedidos']" [hidden]="!isLogged">
    <mat-icon svgIcon="invoice"></mat-icon>
    Pedidos
  </button>
  <button class=" button-transparent user-menu w-100" mat-menu-item [disableRipple]="true"  [routerLink]="['/cliente/metodos-de-pagamento']" [hidden]="!isLogged">
    <mat-icon mat-list-icon svgIcon="wallet"></mat-icon>
    Métodos de pagamento
  </button>
  <button class=" button-transparent user-menu w-100" mat-menu-item [disableRipple]="true"  [routerLink]="['/cliente/endereco']" [hidden]="!isLogged">
    <mat-icon svgIcon="map"></mat-icon>
    Endereços
  </button>
  <button class=" button-transparent user-menu w-100" mat-menu-item [disableRipple]="true"  [routerLink]="['/cliente/favoritos']" [hidden]="!isLogged">
    <mat-icon mat-list-icon svgIcon="heart"></mat-icon>
    Favoritos
  </button>
  <button class=" button-transparent user-menu w-100" mat-menu-item [disableRipple]="true"  [routerLink]="['/cliente/perfil']" [hidden]="!isLogged">
    <mat-icon svgIcon="settings"></mat-icon>
    Editar perfil
  </button>
  <button class=" button-transparent user-menu w-100" mat-menu-item [disableRipple]="true"  (click)="logout()" [hidden]="!isLogged">
    <mat-icon class="item" svgIcon="logout"></mat-icon>
    Sair
  </button>
  <mat-divider class="my-2"></mat-divider>
  <button class=" button-transparent user-menu w-100" mat-menu-item [disableRipple]="true"  [routerLink]="['/venda-conosco']">
    <mat-icon mat-list-icon svgIcon="united"></mat-icon>
    <span>Venda com a gente</span>
  </button>
  <button class=" button-transparent user-menu w-100" mat-menu-item [disableRipple]="true"  [routerLink]="['/atendimento-ao-cliente']" >
    <mat-icon mat-list-icon svgIcon="call-center"></mat-icon>
    <span>Atendimento ao cliente</span>
  </button>
  <button class=" button-transparent user-menu w-100" mat-menu-item [disableRipple]="true"  [routerLink]="['/faq']" >
    <mat-icon mat-list-icon svgIcon="faq"></mat-icon>
    <span>Faq</span>
  </button>


</mat-menu>


<!-- ======= ACCOUNT OPTION MOBILE ======= -->
<mat-menu #menu="matMenu" xPosition="before" class="custom">
  <button class=" button-transparent user-menu w-100" mat-menu-item [disableRipple]="true" (click)="openSidenav = false" [routerLink]="['/cliente/perfil']">
    Editar perfil
  </button>
  <button class=" button-transparent user-menu w-100" mat-menu-item [disableRipple]="true"  (click)="logout()" [hidden]="!isLogged" >
    Sair
  </button>
</mat-menu>

<!-- ======= COOKIES ======= -->
<div *ngIf="!acceptCookies" class="container card-cookies flex-column flex-sm-row">
  <p class="m-0 text-justify"><b>Cookies:</b> a gente guarda estatísticas de visitas para melhorar sua experiência no site. Ao continuar
      navegando, você concorda com a nossa  <a class="link-privacidade" href="/politica-de-privacidade">Política de Privacidade</a>.
  </p>
  <button class="btn-cookies mt-2 mt-sm-0" (click)="cookiesAccept()">
      continuar e fechar
  </button>
</div>
