import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoadingComponent } from 'src/app/utils/dialogs/loading/loading.component';
import { environment } from 'src/environments/environment';
import { CrudService } from '../crud/crud.service';
import { SocketService } from '../socket/socket.service';

@Injectable({
  providedIn: 'root'
})
export class ChatsService extends CrudService{
  dialogLoading: MatDialogRef<LoadingComponent>

  url = environment.apiUrl;
  endpoint = 'chats';

  constructor(http: HttpClient, dialog: MatDialog, private socketService: SocketService ) {
    super(http, dialog);
  }

  getChat(order_id, customer_id, seller_id ) : Promise <any>{
    let params = new HttpParams();

    if(order_id){
      params = params.append('order_id', order_id);
    }
    if(customer_id){
      params = params.append('customer_id', customer_id);
    }
    if(seller_id){
      params = params.append('seller_id', seller_id);
    }

    return this.http.get(`${this.url}/${this.endpoint}`, { params }).toPromise();
  }


  sendMessage(chat_id, message: any) : Promise <any> {
    return this.http.post(`${this.url}/${this.endpoint}/${chat_id}/messages`, message).toPromise()
  }


  getChatById(chat_id) : Promise <any> {
    return this.http.get(`${this.url}/${this.endpoint}/${chat_id}/messages`).toPromise()
  }


  observeChat(chat_id) {
    return this.socketService.socketChatId(chat_id)
    // return this.socket.fromEvent<any>(`new-signals`);
  }



  unsubscribeChat(chat_id){
    this.socketService.unsubscribeChat(chat_id);
  }



}
