<div mat-dialog-content class="d-flex align-items-center justify-content-center">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <i [hidden]="data.error" class="far fa-check-circle fa-3x" style="color: forestgreen;"></i>
            <i [hidden]="!data.error" class="far fa-times-circle fa-3x" style="color: red ;"></i>
        </div>
        <div class="row justify-content-center">
            <h2>{{data.message}}</h2>
        </div>
    </div>
</div>
<div class="d-flex align-items-center justify-content-center">
    <button class="btn-information" mat-raised-button color="primary" (click)="close()">Fechar</button>
</div>