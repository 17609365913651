<div  class="h-100">
  <div class="container-fluid text-center h-100 p-0" style="background-color: #510c76">
    <div class="row body w-100 h-100">
        <div  class="col-0 col-sm-0 col-md-7 col-lg-7 col-xl-7 d-flex justify-content-center align-items-center  container-background "  >
          <!-- <div class="w-75 pt-2 " >
            <div class="d-flex justify-content-center  mb-3">
              <img src="/assets/images/logo-completa.png" class="img-logo" alt="">
            </div>
            <h1 class="mt-5" style="font-size: 2.0rem; line-height: 0.75 rem; font-weight: bold;">Comece a vender hoje mesmo</h1>
          </div> -->

        </div>

        <div class="container-fluid h-100 col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 form-login-side d-flex justify-content-center align-items-center">
            <div class="container d-flex align-items-center justify-content-center" style="height: 70vh">
              <mat-card class="mat-elevation-z8" style="max-width: 500px;">

                <mat-card-content class=" h-100 mt-3">
                  <div class="w-100 h-100 d-flex align-items-center justify-content-center">
                    <h2>Cadastre-se que entraremos em contato</h2>
                  </div>
                  <form [formGroup]="form">

                    <div class="input-group mb-2 d-flex align-items-center justify-content-center">
                      <mat-form-field class="w-100" appearance="outline">
                        <mat-label> Nome do(a) responsável </mat-label>
                        <input matInput placeholder="Nome" formControlName="responsable_name">
                        <mat-error *ngIf="form.controls.responsable_name.invalid && form.controls.responsable_name.touched">
                          <span color="accent" *ngIf="form.controls.responsable_name.errors?.required">
                            O nome é obrigatório!
                          </span>

                        </mat-error>
                      </mat-form-field>

                      <mat-form-field class="w-100" appearance="outline">
                        <mat-label>E-mail</mat-label>
                        <input matInput placeholder="exemplo@exemplo.com" formControlName="email">
                        <mat-error *ngIf="form.controls.email.invalid && form.controls.email.touched">
                          <span color="accent" *ngIf="form.controls.email.errors?.required">
                            O e-mail é obrigatório!
                          </span>
                          <span color="accent" *ngIf="form.controls.email.errors?.email">
                            O formato do e-mail digitado não é válido!
                          </span>
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Digite o seu telefone</mat-label>
                        <lac-mat-country-selector class="h-100 m-auto" matPrefix [selectorFor]="input"
                          [preferredCountries]="['br' ]"
                          [showDialCode]="true"
                          [disableSearch]="false"
                          searchPlaceholder="buscar...">
                        </lac-mat-country-selector>
                        <lac-mat-tel-input #input formControlName="phone_number">
                        </lac-mat-tel-input>
                        <mat-error *ngIf="form.controls.phone_number.invalid && form.controls.phone_number.touched">
                          <span color="accent" *ngIf="form.controls.phone_number.errors?.required">
                            O telefone é obrigatório!
                          </span>
                        </mat-error>
                      </mat-form-field>

                    </div>

                  </form>
                </mat-card-content>

                <mat-card-actions class="d-flex align-items-center mx-auto justify-content-center">
                  <div class="container-fluid">
                    <div class="mb-2 d-flex align-items-center mx-auto justify-content-center">
                      <button class="btn-login" [disabled]="form.invalid" mat-raised-button (click)="submit()" color="primary">Enviar</button>
                    </div>

                  </div>
                </mat-card-actions>


              </mat-card>

            </div>
        </div>
    </div>
  </div>
</div>
