<div *ngIf="subcategories?.length > 0">
  <mat-divider class="my-3" *ngIf="innerWidth >= 576"></mat-divider>
  <div [ngClass]="{'mt-2':bottomSheet}">
    <h2 class="my-2 side-category" *ngIf="innerWidth >= 576">Estilos</h2>
    <mat-selection-list  [disableRipple]="true"  [multiple]="true" class="m-0 p-0 " [(ngModel)]="subcategoriesTerms">
      <mat-list-option  [disableRipple]="true" checkboxPosition="before" style="cursor: pointer;" class="option" *ngFor="let subcategory of subcategories" [value]="subcategory.id"
        (click)="searchSubcategory(subcategory)">
        {{subcategory.name | titlecase}} ({{ subcategory?.productCount || 0}})
      </mat-list-option>
    </mat-selection-list>
    <a (click)="seeAllSubcategories()" class="see-all">{{showAllSubcategories ? 'Ver menos': 'Ver todos'}}</a>
  </div>
</div>
