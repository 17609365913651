import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ValidatorConfirmPassword } from 'src/app/utils/validators/confirmPassword';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/core/storage/store.service';
import { StorageKey } from 'src/app/core/storage/storage.model';
import { CustomersService } from 'src/app/core/services/customers/customers.service';
import { InformationComponent } from '../information/information.component';

const { USER_ID } = StorageKey;

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  hideOld: boolean;
  hidePass: boolean;
  hideConfirm: boolean;
  request: boolean = false;

  formChangePassword: FormGroup;


  constructor(
    private fb: FormBuilder,
    private customersService: CustomersService,
    private storage: StorageService,
    private dialog: MatDialog,
    private dialogChangePassword: MatDialogRef<ChangePasswordComponent>
  ) {
    this.formChangePassword  = this.fb.group({
      old_password: ['', [Validators.required]],
      password: ['', [Validators.required]],
      repeatPassword: ['', [Validators.required]],
    }, { validator: ValidatorConfirmPassword });
  }

  dialogInformation: MatDialogRef<InformationComponent>;
  ngOnInit(): void {
    this.hideOld = true;
    this.hidePass = true;
    this.hideConfirm = true;
  }

  close(){
    this.dialogChangePassword.close();
  }

  save() {
    if(this.formChangePassword.invalid){
      this.formChangePassword.markAllAsTouched();
      return;
    }
    this.request = true;
    let id = this.storage.read(USER_ID);

    this.customersService.update(id, this.formChangePassword.value).then(() => {
      this.dialogInformation = this.dialog.open(InformationComponent, {
        panelClass: 'container-add',
        disableClose: true,
        data: {
          error: false,
          message: 'Sucesso!'
        }
      })
    }).catch(error => {
      this.dialogInformation = this.dialog.open(InformationComponent, {
        panelClass: 'container-add',
        disableClose: true,
        data: {
          error: true,
          message: error.error.message
        }
      })
    }).finally(() => {
      this.request = false;
    })

  }

}
