import { SearchTermResetService } from './../../utils/services/search-term-reset.service';
import { Brand } from './../../core/services/products/_models/Brands';
import { DialogLoginComponent } from './../auth/dialog-login/dialog-login.component';
import { LoginComponent } from './../auth/login/login.component';
import { Product } from './../../core/services/products/_models/Product';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from './../auth/auth.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, pipe } from 'rxjs';
import { distinctUntilChanged, map, pairwise, share, shareReplay, throttleTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { StorageKey } from 'src/app/core/storage/storage.model';
import { StorageService } from 'src/app/core/storage/store.service';
import { CategoriesService } from 'src/app/core/services/categories/categories.service';
import { InformationComponent } from 'src/app/utils/dialogs/information/information.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoadingComponent } from 'src/app/utils/dialogs/loading/loading.component';
import { CartService } from 'src/app/core/services/cart/cart.service';
import { CheckOlderComponent } from 'src/app/utils/dialogs/check-older/check-older.component';
import { PaymentMethodsService } from 'src/app/core/services/payment-methods/payment-methods.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeAddressComponent } from 'src/app/utils/dialogs/change-address/change-address.component';
import { AddressService } from 'src/app/core/services/address/address.service';
import { environment } from 'src/environments/environment';


enum VisibilityState {
  Visible = 'visible',
  Hidden = 'hidden'
}

enum Direction {
  Up = 'Up',
  Down = 'Down'
}

export interface itemDropDown {
  id: string;
  title: string;
  subtitles: any[];
}

const { USER_NAME, CART_PRODUCT, AMOUNT_ITEMS_CART, ZIP_CODE, ACCEPT_COOKIES } = StorageKey;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
 
  animations: [
    trigger('toggle', [
      state(
        VisibilityState.Hidden,
        style({ opacity: 0, visibility: 'hidden' , transform: 'translateY(-100%)' })
      ),
      state(
        VisibilityState.Visible,
        style({ opacity: 1, transform: 'translateY(0)' })
      ),
      transition('* => *', animate('300ms ease-in'))
    ])


  ]
})
export class NavigationComponent implements OnInit{

  urlDelivery = environment.urlDelivery
  dialogInformation: MatDialogRef<InformationComponent>;
  dialogChangeAddress: MatDialogRef<ChangeAddressComponent>;
  dialogLogin: MatDialogRef<DialogLoginComponent>

  // openLoading = this.dialog.open(LoadingComponent, { disableClose: true, panelClass: 'loading-dialog-container' });
  // closeLoading = setTimeout(() => { this.openLoading.close() }, 1500);

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  isLogged: boolean = false;
  clientName: string;
  cartProduct: Product[] = [];
  cartLenght: number;

  statusDropDown = false;

  payments: any[];
  brands: any[] = [];

  openSidenav: boolean = false;

  loading: boolean = true;

  panelOpenState = false;
  panelSubOpenState = false;
  statusAwaitDropDown = false;

  categoryDropDown: itemDropDown = {id: '', title: '', subtitles: []};

  enteredButton = false;
  isMatMenuOpen = false;

  acceptCookies = false;

  scrollValue = 0;
  formSearch = this.fb.group({
    searchTerm: ['', [Validators.required]]
  })

  editableFields = [
    {
      name: 'logradouro',
      complementary: ', '
    },
    {
      name: 'bairro',
      complementary: ' - '
    },
    {

      name: 'localidade',
      complementary: ' - '
    },
    {

      name: 'uf',
      complementary: '.'
    }
  ];
  categoriesAllList: any[] = [];
  buyCategoriesList: any[] = [];
  categoryList: any;
  checkOlderDialogRef: MatDialogRef<CheckOlderComponent>
  innerWidth: number;
  showInfos = false;
  isVisible: boolean = true;
  zipCode: any = null;
  addressValue: string = '';
  constructor(
    private categoryService: CategoriesService,
    private fb: FormBuilder,
    private breakpointObserver: BreakpointObserver,
    private authService: AuthService,
    private router: Router,
    private storage: StorageService,
    private dialog: MatDialog,
    private cartService: CartService,
    private paymentMethodsService: PaymentMethodsService,
    private addressService: AddressService,
    private searchTermResetService: SearchTermResetService,
  ) {
    this.cartLenght = this.storage.read(AMOUNT_ITEMS_CART);
    this.acceptCookies = this.storage.read(ACCEPT_COOKIES);
    this.zipCode = this.storage.read(ZIP_CODE);
    const check = this.storage.readSession('checkOlder');
    if(!check){
      this.checkOlderDialogRef = this.dialog.open(CheckOlderComponent, {disableClose: true});
    }
    this.innerWidth = window.innerWidth;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;

  }



  shoppingCartOpen(){
    
  }

  shoppingCartClose(){
    
  }

  changeAddress(){
    this.dialogChangeAddress = this.dialog.open(ChangeAddressComponent, );
    this.dialogChangeAddress.afterClosed().subscribe( data=> {
      this.getZipCode();
    })
  }

  scroll(event){
    
    if(event.srcElement.scrollTop >= 0){
      if(event.srcElement.scrollTop > this.scrollValue && event.srcElement.scrollTop > 175 && !(this.panelOpenState || this.panelSubOpenState) ){
        this.isVisible = false;
      } else {
        this.isVisible = true;
      }
      this.scrollValue = event.srcElement.scrollTop;
    }
  }
  ngOnInit(): void {
    this.logged();
    this.getCategory();
    this.getCart();
    this.observeChangeCart();
    this.getAllBrands();
    this.getZipCode();
    this.searchTermResetService.emitReset.subscribe(() => {
      this.formSearch.reset();
    })
  }

  ngAfterViewInit(){
    this.scrollInit();
  }

  scrollInit() {
    setTimeout(() => {
     const element: HTMLElement = document.getElementById('start');
     element.scrollIntoView({ behavior:"smooth" });
    }, 100)
   }

  observeChangeCart(){
    this.cartService.changeAmount.subscribe(item => {
      this.cartLenght = item;
    })
  }

  getZipCode(){
    this.zipCode = this.storage.read(ZIP_CODE);
    if(this.zipCode){
      this.addressService.getAddressByZip(this.zipCode).then(res => {
        if(res?.erro){
          
          this.zipCode = null;
          this.storage.remove(ZIP_CODE);
        } else{
          this.addressValue = this.editableFields.reduce( (acc, item) => acc + res[item.name] + (res[item.name] !== ''? item.complementary: '' ) ,'');
        }

      }).catch(err => {
        

      })
    }
  }



  getStyleBtn(i): string {
    const btnCategoryRef = document.getElementById('btn-category-'+i)
    return `min-width: ${btnCategoryRef.clientWidth}px; height: ${btnCategoryRef.clientHeight}px; transform: translateY(-${btnCategoryRef.clientHeight}px);  `
  }

  logged() {
    this.isLogged = this.authService.isLogged();
    this.clientName = this.storage.read(USER_NAME);
  }

  login(){
    this.dialogLogin = this.dialog.open(DialogLoginComponent, {
      panelClass: 'container-add',
      disableClose: true,
      maxWidth: '90%',
    })
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/'], { replaceUrl: true }).then(() => {
      window.location.reload();
    });
  }

  search() {
    if(this.formSearch.invalid){
      return;
    }
    this.router.navigate(['/busca' ], { queryParams: { term: this.formSearch.controls.searchTerm.value, order_by_price: 'ASC' } });
  }

  searchAllCategories() {
    this.router.navigate(['/busca' ], { queryParams: { order_by_price: 'ASC' } });
  }

  searchCategory(param){
    this.scrollInit();
    setTimeout(() => {
      this.router.navigate(['/busca', ], {  queryParams: { category: param.id, order_by_price: 'ASC' }});
    }, 500);
  }

  searchSubcategory(data) {
    this.scrollInit();
    setTimeout(() => {
      this.router.navigate(['/busca'], { queryParams: { category: data.category_id, subcategory: data.id, order_by_price: 'ASC'  }});
    }, 500);
  }

  getCategory() {
    // this.openLoading;
    this.categoryService.getAll().then(response => {
      this.categoriesAllList = response;
      this.categoryList = response;
      this.categoryList = this.categoryList.slice( 0, 4 );
      this.orderBuyCategories(7);
      this.loading = false;
      
    }).catch(error => {
      this.dialogInformation = this.dialog.open(InformationComponent, {
        panelClass: 'container-add',
        disableClose: true,
        data: {
          error: true,
          message: error.error.message
        }
      })
    }).finally(() => {

    })
  }

  getCart(){
    this.cartProduct = (this.storage.read(CART_PRODUCT) || []);
    if (this.cartProduct.length) {
      this.cartLenght = this.cartProduct.length;
    } else {
      this.cartLenght = null;
    }
  }

  getAllBrands() {
    // this.openLoading;
    this.paymentMethodsService.getAll().then(res => {
      this.payments = res;

      this.payments.forEach(item => {
        item.brands.forEach( brand => {
          if(!this.brands.find(currentBrand => brand.name.toUpperCase().trim() === currentBrand.name.toUpperCase().trim())){
            this.brands.push(brand);
          }
        })
      })

    }).catch(error => {
      this.dialogInformation = this.dialog.open(InformationComponent, {
        disableClose: true,
        panelClass: 'container-add',
        data: {
          error: true,
          message: error.error.message
        }
      })
    }).finally(() => {
      // this.closeLoading;
    })
  }

  enter(){
    this.isOpen = true;
  }
  leave(){
    this.isOpen = false
  }

  cookiesAccept() {
    this.acceptCookies = true;
    this.storage.save(ACCEPT_COOKIES, this.acceptCookies);
  }

  isOpen = false;

  showDropDown(index: number) {
    if(this.innerWidth >= 992) {
      this.categoryList.forEach((element, i) => {
        if (index == i && element.subcategories.length > 0) {
          document.getElementById('dropdown-item-' + i)?.classList.toggle('active-dropdown-menu');
        }
        else {
          document.getElementById('dropdown-item-' + i)?.classList.remove('active-dropdown-menu');
        }
      });
      this.statusDropDown = true;
    }
  }

  removeDropdown() {
    if(this.innerWidth >= 992) {
      this.statusDropDown = false;
      setTimeout(() => {
        if(!this.statusDropDown) {
          this.categoryList.forEach((element, i) => {
            document.getElementById('dropdown-item-' + i)?.classList.remove('active-dropdown-menu');
          });
        }
      },50)
    }
  }

  removeDropdownCategory() {
    this.panelOpenState=false;
    setTimeout(() => {
      if(!this.panelOpenState) {
        this.panelSubOpenState = false;
      }
    },50)
  }

  changeCategoryDropDown(id, title, subtitles){
    this.categoryDropDown.id = id;
    this.categoryDropDown.title = title;
    this.categoryDropDown.subtitles = subtitles;
  }

  orderBuyCategories(quantity: number) {
    this.categoriesAllList.slice( 0, quantity).forEach(item => {
      item.subcategories.length > 0 ? this.buyCategoriesList.unshift(item) : this.buyCategoriesList.push(item);
    });
  }

  awaitDropDown() {
    this.statusAwaitDropDown = true;
    setTimeout(() => {
      if(this.statusAwaitDropDown) {
        this.panelOpenState=true;
        this.panelSubOpenState=false;
      }
    },500)
  }

}
