
<div class="container">
  <div>
    <h3> Escolha um endereço cadastrado</h3>
  </div>
  <div class="row p-0 pb-2">
    <mat-card class="col-sm-6 mat-elevation-z2" *ngFor="let address of addresses">
      <mat-card-header>

        <mat-card-title>  {{address.receiver_name }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>{{address.address?.full_address}}</p>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button class="w-100" color="primary" (click)="select(address)"> Usar</button>
      </mat-card-actions>
    </mat-card>

  </div>

</div>
