<div class="custom-not-found d-flex align-items-center row">
  <div class="col-12 col-sm-6 col-md-6  ">
    <ng-lottie [width]="innerWidth > 576 ? '65vw': '100vw'" [height]="innerWidth > 576 ? '65vh': 'auto'" [options]="options" (animationCreated)="animationCreated($event)"></ng-lottie>
  </div>


  <div class="container text-center col-12 col-sm-6 com-md-6">
      <div class="pb-3">
          <span style="font-size: 8rem; color: white;">
              404
          </span>
      </div>
      <div>
          <h1 style="color: white;"> Página não encontrada! </h1>
      </div>
      <div>
          <a class="button-transparent" [routerLink]="['/']">Voltar à página inicial</a>
          <!-- <button mat-raised-button [routerLink]="['']">Voltar</button> -->
      </div>

  </div>
</div>
