import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CustomersService } from 'src/app/core/services/customers/customers.service';
import { StorageService } from 'src/app/core/storage/store.service';
import { StorageKey } from 'src/app/core/storage/storage.model';
const { USER_ID } = StorageKey;

@Component({
  selector: 'app-list-address-select',
  templateUrl: './list-address-select.component.html',
  styleUrls: ['./list-address-select.component.scss']
})
export class ListAddressSelectComponent implements OnInit {

  @Output() emitAddress = new EventEmitter();

  userId: string = '';
  addresses: any[] = [];
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private customersService: CustomersService,
    private storage: StorageService,

  ) {
    this.userId = this.storage.read(USER_ID);
  }

  ngOnInit(): void {
    this.getAddresses();
  }

  getAddresses(){
    this.customersService.getById(this.userId).then(res => {

      this.addresses = res.addresses;
    })
  }

  select(address){
    
    this.emitAddress.emit(address)
  }


}
