import { NotFoundModule } from './utils/pages/not-found/not-found.module';
import { ChangePasswordModule } from './utils/dialogs/change-password/change-password.module';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { NgxMaskModule } from 'ngx-mask';
import { InterceptorService } from './core/services/crud/interceptor.service';
import { SaleWithUsModule } from './pages/sale-with-us/sale-with-us.module';
import { LoadingModule } from './utils/dialogs/loading/loading.module';
import { ListSubcategoryModule } from './utils/list-subcategory/list-subcategory.module';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';
import { ChatsService } from './core/services/chats/chats.service';


registerLocaleData(ptBr)

export function playerFactory() {
  return player;
}


const config: SocketIoConfig = { url: environment.urlSocket , options: { 
  transports: ['websocket'],
}};


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports:[
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    HttpClientModule,
    MatDialogModule,
    MatCardModule,
    RouterModule,
    NgxMaskModule.forRoot(),
    LottieModule.forRoot({ player: playerFactory }),
    ChangePasswordModule,
    SaleWithUsModule,
    NotFoundModule,
    LoadingModule,
    ListSubcategoryModule,
    SocketIoModule.forRoot(config)
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true},
    ChatsService,
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
