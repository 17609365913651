import { trigger, state, style, transition, animate } from '@angular/animations';

import { AfterViewInit, Component, HostBinding, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { throttleTime, map, pairwise, distinctUntilChanged, share, filter } from 'rxjs/operators';
import { CartService } from 'src/app/core/services/cart/cart.service';
import { StorageKey } from 'src/app/core/storage/storage.model';
import { StorageService } from 'src/app/core/storage/store.service';
import { SearchTermResetService } from 'src/app/utils/services/search-term-reset.service';

enum VisibilityState {
  Visible = 'visible',
  Hidden = 'hidden'
}

enum Direction {
  Up = 'Up',
  Down = 'Down'
}
const { USER_NAME, CART_PRODUCT, AMOUNT_ITEMS_CART } = StorageKey;



@Component({
  selector: 'app-sticky-header',
  template: ``,
  templateUrl: './sticky-header.component.html',
  styleUrls: ['./sticky-header.component.scss'],
  styles: [
    `
      :host {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 9999999999;
      }
    `
  ],
  animations: [
    trigger('toggle', [
      state(
        VisibilityState.Hidden,
        style({ opacity: 0, transform: 'translateY(-100%)' })
      ),
      state(
        VisibilityState.Visible,
        style({ opacity: 1, transform: 'translateY(0)' })
      ),
      transition('* => *', animate('300ms ease-in'))
    ])
  ]
})

export class StickyHeaderComponent implements OnInit {
  @Input() sidenav;
  @Input() isVisible = true;
  formSearch = this.fb.group({
    searchTerm: ['', [Validators.required]]
  })
  cartLenght: any;
  cartProduct: any;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private cartService: CartService,
    private storage: StorageService,
    private searchTermResetService: SearchTermResetService,

    ) {
      this.cartLenght = this.storage.read(AMOUNT_ITEMS_CART);

  }


  ngOnInit(){
    this.getCart();
    this.observeChangeCart();
    this.searchTermResetService.emitReset.subscribe(() => {
      this.formSearch.reset();
    })
  }

  observeChangeCart(){
    this.cartService.changeAmount.subscribe(item => {
      this.cartLenght = item;
    })
  }

  getCart(){
    this.cartProduct = (this.storage.read(CART_PRODUCT) || []);
    if (this.cartProduct.length) {
      this.cartLenght = this.cartProduct.length;
    } else {
      this.cartLenght = null;
    }
  }


  ngOnChanges(changes: SimpleChanges): void {
    if(this.isVisible === undefined){
      this.isVisible = true
    }

  }
  // get toggle(): string {
  //   return this.isVisible ? VisibilityState.Visible : VisibilityState.Hidden;
  // }

  // ngAfterViewInit() {
  //   const scroll$ = fromEvent(window, 'scroll').pipe(
  //     throttleTime(10),
  //     map(() => window.pageYOffset),
  //     pairwise(),
  //     map(([y1, y2]): any => {
  //       
  //       return y2 < y1 ? Direction.Up : Direction.Down}),
  //     distinctUntilChanged(),
  //     share()
  //   );



  //   const scrollUp$ = scroll$.pipe(
  //     filter(direction => direction === Direction.Up)
  //   );

  //   const scrollDown = scroll$.pipe(
  //     filter(direction => direction === Direction.Down)
  //   );

  //   scrollUp$.subscribe(() => (this.isVisible = true));
  //   scrollDown.subscribe(() => (this.isVisible = false));

  //   scroll$.subscribe(item => 
  // }


  search() {
    this.router.navigate(['/busca'], { queryParams: { term: this.formSearch.controls.searchTerm.value } });
  }
}
