<mat-card class="mat-elevation-z0">
  <div class="d-flex justify-content-center mb-3">
    <img src="/assets/images/Logo-cor.png" class="img-logo" alt="">
  </div>
  <mat-card-content class=" m-0">
    <form [formGroup]="formLogin">

      <div class="input-group mb-1 d-flex align-items-center justify-content-center">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Digite o seu e-mail</mat-label>
          <input matInput placeholder="exemplo@exemplo.com" formControlName="email">
          <mat-error *ngIf="formLogin.controls.email.invalid && formLogin.controls.email.touched">
            <span color="accent" *ngIf="formLogin.controls.email.errors?.required">
              O e-mail é obrigatório!
            </span>
            <span color="accent" *ngIf="formLogin.controls.email.errors?.email">
              O formato do e-mail digitado não é válido!
            </span>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Digite a sua senha</mat-label>
          <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" (keyup.enter)="login()">
          <button class="hidden-password" mat-icon-button matSuffix (mouseup)="hide = !hide"
            [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide" tabindex="-1">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
          <mat-error *ngIf="formLogin.controls.password.invalid && formLogin.controls.password.touched">
            <span color="accent" *ngIf="formLogin.controls.password.errors?.required">
              A senha é obrigatória!
            </span>
          </mat-error>
        </mat-form-field>
      </div>

    </form>
  </mat-card-content>

  <mat-card-actions class=" m-0 d-flex align-items-center mx-auto justify-content-center">
    <div class="container-fluid">
      <div class="mb-1 d-flex align-items-center mx-auto justify-content-center">
        <button class="btn-login" [disabled]="formLogin.invalid" mat-raised-button (click)="login()"
          color="primary">Entrar</button>
      </div>
      <div *ngIf="isDialog">
        <mat-card-content class="d-flex align-items-center justify-content-center">
          <button class="btn-hide button-transparent" mat-dialog-close >Cancelar</button>
        </mat-card-content>
      </div>
    </div>
  </mat-card-actions>

  <mat-card-content class="d-flex align-items-center justify-content-around">
    
      <div class="d-flex align-items-center mx-auto justify-content-center">
        <button class="btn-hide button-transparent" (click)="register()" >Criar uma conta</button>
      </div>
      <div class="d-flex align-items-center mx-auto justify-content-center">
        <button class="btn-hide button-transparent" (click)="forgot()" >Esqueceu sua senha?</button>
      </div>
    
  </mat-card-content>
  <!-- <mat-card-content class="d-flex align-items-center justify-content-center">
    <p>Ainda não possui uma conta?<a  mat-button>Criar uma conta</a></p>
  </mat-card-content> -->
</mat-card>
