import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SearchTermResetService {

  emitReset = new EventEmitter();
  
  constructor() { }

  reset() {
    this.emitReset.emit();
  }
}
