import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CustomersService } from 'src/app/core/services/customers/customers.service';
import { StorageService } from 'src/app/core/storage/store.service';
import { StorageKey } from 'src/app/core/storage/storage.model';
import { AuthService } from 'src/app/pages/auth/auth.service';
import { MatDialogRef } from '@angular/material/dialog';
import { AddressCustomer } from 'src/app/core/models/AddressCustomer';

const {USER_ID, ZIP_CODE} = StorageKey;
@Component({
  templateUrl: './change-address.component.html',
  styleUrls: ['./change-address.component.scss']
})
export class ChangeAddressComponent implements OnInit {

  form = this.fb.group({
    zipcode: ['', [Validators.required]],
  })

  addresses: any[] = [];
  userId: any;
  isLogged: any;
  zipcode: any;
  constructor(
    private fb: FormBuilder,
    private customersService: CustomersService,
    private storage: StorageService,
    private authService: AuthService,
    private dialogChangeAddress: MatDialogRef<ChangeAddressComponent>

  ) {
    this.logged();
    if(this.isLogged){
      this.userId = this.storage.read(USER_ID);
    }
    this.zipcode = this.storage.read(ZIP_CODE);
    if(this.zipcode){
      this.form.controls.zipcode.setValue(this.zipcode);
    }

  }

  logged() {
    this.isLogged = this.authService.isLogged();

  }


  ngOnInit(): void {
    if(this.isLogged){
      this.getAddresses();
    }

  }

  respAddress( address: AddressCustomer){

    this.storage.save(ZIP_CODE, address.address.zipcode );
    this.close();
  }

  getAddresses(){
/*     this.customersService.getUserById(this.userId).subscribe({
      next: (res: any) => {
        console.log(res)
        this.addresses = res.addresses;
      },
      error: (err: any) => {
        console.log(err)
      },
    }) */

    this.customersService.getById(this.userId).then(res => {
      this.addresses = res.addresses;

    })
  }
  save(){
    if(this.form.valid){
      this.storage.save(ZIP_CODE, this.form.controls.zipcode.value);
      this.close()
    }
  }

  close(){
    this.dialogChangeAddress.close();
  }

}
