import { StorageService } from 'src/app/core/storage/store.service';
import { Injectable, EventEmitter } from '@angular/core';
import { StorageKey } from '../../storage/storage.model';

const { CART_PRODUCT, AMOUNT_ITEMS_CART } = StorageKey;

@Injectable({
  providedIn: 'root'
})
export class CartService {

  itemsCart: any;
  amountItemsCart: number;
  changeAmount = new EventEmitter();

  constructor(
    private storage: StorageService,
  ) {
    this.itemsCart = this.storage.read(CART_PRODUCT);
    this.amountItemsCart = this.storage.read(AMOUNT_ITEMS_CART);
  }


  countItems(itemsCart) {
    this.amountItemsCart = itemsCart.length;
    this.changeAmount.emit(this.amountItemsCart);
    this.storage.save(AMOUNT_ITEMS_CART, this.amountItemsCart);
  }



}
