<div class="d-flex align-items-end justify-content-end">
  <i mat-dialog-close class="fas fa-times fa-2x" ></i>
</div>
<h2 mat-dialog-title>As melhores ofertas e condições de frete para a sua região </h2>
<div mat-dialog-content>

  <!-- <div>
    <h4>As opções e velocidade de entrega podem variar de acordo com a região</h4>
  </div> -->

  <div *ngIf="!isLogged" class="container mt-2 ">
    <button mat-raised-button color="primary" [routerLink]="['/autenticacao/entrar']" >  Faça Login </button>
    <div class="w-100 d-flex justify-content-between align-items-center">
      <mat-divider style="width: 45%"></mat-divider>
      <h3> ou </h3>
      <mat-divider style="width: 45%"></mat-divider>
    </div>

  </div>

  <form [formGroup]="form">
    <div class="row p-0 input-group mb-2">

        <mat-form-field class="col-sm-8" appearance="outline">
          <mat-label>Digite seu CEP</mat-label>
          <input matInput [mask]="'00000-000'" formControlName="zipcode">
        </mat-form-field>

        <div class="col-sm-4 d-flex align-items-center justify-content-center">
          <button mat-raised-button [disabled]="form.invalid" class="w-100 " color="primary" (click)="save()" >Usar</button>
        </div>

    </div>


  </form>

  <div *ngIf="isLogged" class="container mt-2">
    <div class="w-100 d-flex justify-content-between align-items-center">
      <mat-divider style="width: 45%"></mat-divider>
      <h3> ou </h3>
      <mat-divider style="width: 45%"></mat-divider>
    </div>
    <!-- <app-card-address *ngFor="let addressCustomer  of addresses" [addressCustomer]="addressCustomer" class="col-sm-4 mt-3"></app-card-address> -->
    <app-list-address-select (emitAddress)="respAddress($event)"></app-list-address-select>
  </div>


</div>
<div mat-dialog-actions>

  <!-- <button mat-raised-button class="btn-cancel ml-2" (click)="close()" type="button">Cancelar</button> -->
</div>
