<mat-divider class="my-2"></mat-divider>

<footer>
  <!-- ========= FORMAS DE PAGAMENTO ========= -->
  <div class="mt-3 text-start">
    <div  class="container pb-2 pt-3" >
      <div  class="container pb-2 pt-3" >
        <h4>PAGUE COM</h4>
        <div class="row w-100 d-flex align-items-center justify-content-start">
          <div class="col-lg-9 col-12">
            <img *ngFor="let flag of cardFlags" class="m-1" src="{{flag.logo_url}}" height="50px" alt="payments">
          </div>
          <div class="col-lg-3 col-12 mt-4 mt-lg-0 d-flex align-items-center justify-content-around">
            <div class="icon-social-footer socials-item">
              <a href="https://www.facebook.com/vendolabr" target="_blank">
                <mat-icon svgIcon="footer-facebook"></mat-icon>
              </a>
            </div>
            <div class="icon-social-footer socials-item">
              <a href="https://www.instagram.com/vendolabr/" target="_blank">
                <mat-icon svgIcon="footer-instagram"></mat-icon>
              </a>
            </div>
            <div class="icon-social-footer socials-item">
              <a href="https://twitter.com/vendolabrasil" target="_blank">
                <mat-icon svgIcon="footer-twitter"></mat-icon>
              </a>
            </div>
            <div class="icon-social-footer socials-item">
              <a href="https://www.youtube.com/channel/UC4i5W2JnMRHQHt5NzzXg_ew" target="_blank">
                <mat-icon svgIcon="footer-youtube"></mat-icon>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--========= FOOTER =========-->
  <div class="footer-gray mt-3 text-start">
    <div  class="container pb-2 pt-3" >
      <div  class="container pb-2 pt-3" >
        <div class="row w-100 d-flex justify-content-between align-items-start mb-2">
          <div class="col-lg-3 col-md-7 col-sm-6 col-12 px-0">
            <h2>Central do cliente</h2>
            <a class="mb-2" [routerLink]="['/politica-de-privacidade']" style="cursor: pointer;"><p>Política de Privacidade</p></a>
            <a class="mb-2" [routerLink]="['/termos-e-condicoes-de-uso']" style="cursor: pointer;"><p>Termos e Condições de Uso</p></a>
            <a class="mb-2" [routerLink]="['/politica-de-entrega']" style="cursor: pointer;"><p>Política de Entrega</p></a>
            <a class="mb-2" [routerLink]="['/formas-de-pagamento']" style="cursor: pointer;"><p>Formas de Pagamento</p></a>
            <a class="mb-2" [routerLink]="['/trocas-e-devolucoes']" style="cursor: pointer;"><p>Trocas e devoluções</p></a>
          </div>
          <div class="col-lg-3 col-md-5 col-sm-6 col-12 mt-4 mt-sm-0 px-0">
            <h2>Atendimento</h2>
            <span class="d-flex align-items-center justify-content-start mb-3">
              <mat-icon class="mr-2">whatsapp</mat-icon>
              <p class="mb-0" style="font-size: 1.2rem;">(37) 99815-5803</p>
            </span>
            <p class="mb-0" style="font-size: 0.7rem;">de Seg. à Sex. das 08:00 às 18:00</p>
            <p class="mb-0" style="font-size: 0.7rem;">contato@eubebo.com.br</p>
            <p class="mb-0 mt-2" style="font-size: 0.7rem;">eubebo - CNPJ: 41.172.138/0001-55</p>
            <p class="mb-0" style="font-size: 0.7rem;">Endereço: Rua Barão de Piumhi, 392 - Centro, Formiga/MG - CEP 35.570-128</p>
          </div>
          <div class="col-lg-3 col-md-7 col-sm-6 col-12 mt-4 mt-lg-0 px-0">
            <h2>Institucional</h2>
            <a class="mb-2" [routerLink]="['/quem-somos']" style="cursor: pointer;"><p>Quem Somos</p></a>
            <a class="mb-2" [routerLink]="['/venda-conosco']" style="cursor: pointer;"><p>Venda conosco</p></a>
            <!-- <p class="mb-2" style="cursor: pointer;">Trabalhe conosco</p> -->
          </div>
          <div class="col-lg-3 col-md-5 col-sm-6 col-12 mt-4 mt-lg-0 px-0 d-lg-flex align-items-start justify-content-center">
            <h2>Loja Segura</h2>
            <!-- <div class="d-flex align-items-center justify-content-start">
              <img class="m-1" src="https://selo.compreconfie.com.br/reputation/100515.png" height="50px" alt="Compre e confie">
              <img class="m-1" src="https://newimgebit-a.akamaihd.net/ebitBR/selo/img_552.png" height="50px" alt="Ebit">
              <div class="ra m-1 px-2 d-flex align-items-center justify-content-start">
                <img class="mr-2 my-1" style="height:auto;width:38px;" src="https://s3.amazonaws.com/raichu-beta/selos/assets/images/ra-1000.svg" alt="RA 1000" title="RA 1000">
                <div>
                  <div class="ra-widget-reputation-text">RA 1000</div>
                  <img style="height:12.75px;width:80px;" alt="Selo Reclame Aqui" src="https://s3.amazonaws.com/raichu-beta/selos/assets/images/reclame-aqui-logo.svg">  
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

