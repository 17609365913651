import { Component } from '@angular/core';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';


declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'eubebo do Brasil - Comercio Eletronico de Bebidas LTDA';



  // ÍCONES PERSONALIZADOS

  constructor(
    public matIconRegistry: MatIconRegistry,
    public domSanitizer: DomSanitizer,
    private router: Router

  ) {

    this.matIconRegistry.addSvgIcon('sad-icon', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/sad.svg"));
    this.matIconRegistry.addSvgIcon('shopping-cart-icon', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/shopping-cart.svg"));
    this.matIconRegistry.addSvgIcon('facebook', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/facebook.svg"));
    this.matIconRegistry.addSvgIcon('instagram', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/instagram.svg"));
    this.matIconRegistry.addSvgIcon('twitter', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/twitter.svg"));
    this.matIconRegistry.addSvgIcon('delete', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/delete.svg"));
    this.matIconRegistry.addSvgIcon('logout', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/logout.svg"));
    this.matIconRegistry.addSvgIcon('settings', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/settings.svg"));
    this.matIconRegistry.addSvgIcon('order', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/order.svg"));
    this.matIconRegistry.addSvgIcon('basket', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/basket.svg"));
    this.matIconRegistry.addSvgIcon('invoice', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/invoice.svg"));
    this.matIconRegistry.addSvgIcon('address', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/address.svg"));
    this.matIconRegistry.addSvgIcon('map', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/map.svg"));
    this.matIconRegistry.addSvgIcon('categories', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/categories.svg"));
    this.matIconRegistry.addSvgIcon('heart', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/heart.svg"));
    this.matIconRegistry.addSvgIcon('home', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/home.svg"));
    this.matIconRegistry.addSvgIcon('united', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/united.svg"));
    this.matIconRegistry.addSvgIcon('faq', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/faq.svg"));
    this.matIconRegistry.addSvgIcon('wallet', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/wallet.svg"));
    this.matIconRegistry.addSvgIcon('login', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/login.svg"));
    this.matIconRegistry.addSvgIcon('register', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/register.svg"));
    this.matIconRegistry.addSvgIcon('user', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/user.svg"));
    this.matIconRegistry.addSvgIcon('add', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/add.svg"));
    this.matIconRegistry.addSvgIcon('add-v2', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/button.svg"));
    this.matIconRegistry.addSvgIcon('edit', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/edit-button.svg"));
    this.matIconRegistry.addSvgIcon('heart-full', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/heart-full.svg"));
    this.matIconRegistry.addSvgIcon('heart-empty', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/heart-empty.svg"));
    this.matIconRegistry.addSvgIcon('star-empty', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/star-empty.svg"));
    this.matIconRegistry.addSvgIcon('star-full', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/star-full.svg"));
    this.matIconRegistry.addSvgIcon('star-half', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/star-half.svg"));
    this.matIconRegistry.addSvgIcon('add-user', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/add-user.svg"));
    this.matIconRegistry.addSvgIcon('more', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/more.svg"));
    this.matIconRegistry.addSvgIcon('carts', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/carts.svg"));
    this.matIconRegistry.addSvgIcon('call-center', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/call-center.svg"));
    this.matIconRegistry.addSvgIcon('lupa', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/lupa.svg"));
    this.matIconRegistry.addSvgIcon('share', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/share.svg"));
    this.matIconRegistry.addSvgIcon('whatsapp', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/whatsapp.svg"));
    this.matIconRegistry.addSvgIcon('copy', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/copy.svg"));
    this.matIconRegistry.addSvgIcon('facebook-share', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/facebook-share.svg"));
    this.matIconRegistry.addSvgIcon('instagram-share', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/instagram-share.svg"));
    this.matIconRegistry.addSvgIcon('twitter-share', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/twitter-share.svg"));
    this.matIconRegistry.addSvgIcon('filter', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/filter.svg"));
    this.matIconRegistry.addSvgIcon('printer', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/printer.svg"));
    this.matIconRegistry.addSvgIcon('barcode', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/barcode.svg"));
    this.matIconRegistry.addSvgIcon('calendar', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/calendar.svg"));
    this.matIconRegistry.addSvgIcon('credit-card', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/credit-card.svg"));
    this.matIconRegistry.addSvgIcon('box', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/box.svg"));
    this.matIconRegistry.addSvgIcon('approval', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/approval.svg"));
    this.matIconRegistry.addSvgIcon('fast', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/fast-delivery.svg"));
    this.matIconRegistry.addSvgIcon('jcb', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/jcb.svg"));
    this.matIconRegistry.addSvgIcon('discover', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/discover.svg"));
    this.matIconRegistry.addSvgIcon('dinners-club', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/dinners-club.svg"));
    this.matIconRegistry.addSvgIcon('american-express', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/american-express.svg"));
    this.matIconRegistry.addSvgIcon('mastercard', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/mastercard.svg"));
    this.matIconRegistry.addSvgIcon('visa', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/visa.svg"));
    this.matIconRegistry.addSvgIcon('cvv', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/cvv.svg"));

    this.matIconRegistry.addSvgIcon('footer-facebook', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/footer-facebook.svg"));
    this.matIconRegistry.addSvgIcon('footer-instagram', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/footer-instagram.svg"));
    this.matIconRegistry.addSvgIcon('footer-twitter', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/footer-twitter.svg"));
    this.matIconRegistry.addSvgIcon('footer-youtube', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/footer-youtube.svg"));
    
    this.matIconRegistry.addSvgIcon('email-send', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/email-send.svg"));
    this.matIconRegistry.addSvgIcon('delivery', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/entrega-rapida.svg"));
    this.matIconRegistry.addSvgIcon('pix', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/pix.svg"));
    this.matIconRegistry.addSvgIcon('scan', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/scan.svg"));

  }

  ngOnInit() {
    this.setUpAnalytics();
  }

  setUpAnalytics() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      console.log(event.urlAfterRedirects);
      gtag('config', 'G-P15J9HWEL5', { page_path: event.urlAfterRedirects });
    });
  }

}


