import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { CrudService } from '../crud/crud.service';


@Injectable({
  providedIn: 'root'
})
export class PaymentMethodsService extends CrudService {

  endpoint = 'payment-methods';
  url = environment.apiUrl;

  constructor( http: HttpClient, dialog: MatDialog) {
    super(http, dialog)
   }

  getAllFilter(platform_type: string): Promise<any> {

    let params = new HttpParams();

    if(platform_type){
      params = params.append('platform_type', platform_type);
    }
      
    return this.http.get(`${this.url}/${this.endpoint}/`, {params }).toPromise()
  }
}