import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  urlViaCep = 'https://viacep.com.br/ws';
  constructor(private http: HttpClient) { }

  getAddressByZip(zip) : Promise <any>{
    return this.http.get(`${this.urlViaCep}/${zip}/json/`).toPromise();
  }

  async getAddressLatAndLong(cep: string, city:string, street:string, street_number: string): Promise<any> {
    const street_formated = street?.replace(' ', '%20')
    return this.http.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${cep}_${city}_${street_formated}_${street_number}&key=AIzaSyD4Dx_pOUPoDlTUQ1ZEov8__nfAvMHy4ao`).toPromise()
  }
}
