import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SaleWithUsRoutingModule } from './sale-with-us-routing.module';
import { SaleWithUsComponent } from './sale-with-us.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { LacMatTelInputModule } from 'lac-mat-tel-input';


@NgModule({
  declarations: [SaleWithUsComponent],
  imports: [
    CommonModule,
    SaleWithUsRoutingModule,
    MatDialogModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    LacMatTelInputModule,
  ]
})
export class SaleWithUsModule { }
