import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './utils/pages/not-found/not-found.component';

const routes: Routes = [
  // {
  //   path: '',
  //   loadChildren: () =>
  //     import('./pages/splash-screen/splash-screen.module').then(
  //       m => m.SplashScreenModule,
  //     ),
  // },
  {
    path: '',
    loadChildren: () =>
      import('./pages/navigation/navigation.module').then(
        m => m.NavigationModule,
      ),
  },
  {
    path: 'autenticacao',
    loadChildren: () =>
      import('./pages/auth/auth.module').then(
        m => m.AuthModule ,
      ),
  },
  // { path: 'atendimento-ao-cliente', loadChildren: () => import('./utils/pages/customer-service/customer-service.module').then(m => m.CustomerServiceModule) },
  // { path: 'payment-methods', loadChildren: () => import('./pages/client/client-area/payment-methods/payment-methods.module').then(m => m.PaymentMethodsModule) },
  // { path: 'favorites', loadChildren: () => import('./pages/client/client-area/favorites/favorites.module').then(m => m.FavoritesModule) },
  // { path: 'list-subcategory', loadChildren: () => import('./utils/list-subcategory/list-subcategory.module').then(m => m.ListSubcategoryModule) },
  // { path: 'finish-order', loadChildren: () => import('./pages/client/client-area/finish-order/finish-order.module').then(m => m.FinishOrderModule) },
  // { path: 'credit_card_selected', loadChildren: () => import('./utils/components/credit-card-selected/credit-card-selected.module').then(m => m.CreditCardSelectedModule) },
  // { path: 'lifeline', loadChildren: () => import('./utils/components/lifeline/lifeline.module').then(m => m.LifelineModule) },
  // { path: 'choise-dialog', loadChildren: () => import('./utils/dialogs/choise-dialog/choise-dialog.module').then(m => m.ChoiseDialogModule) },
  // { path: 'directives', loadChildren: () => import('./core/directives/directives.module').then(m => m.DirectivesModule) },
  
  { path: 'politica-de-privacidade', loadChildren: () => import('./utils/pages/privacy/privacy.module').then(m => m.PrivacyModule) },
  { path: 'termos-e-condicoes-de-uso', loadChildren: () => import('./utils/pages/terms/terms.module').then(m => m.TermsModule) },
  { path: 'politica-de-entrega', loadChildren: () => import('./utils/pages/term-delivery/term-delivery.module').then(m => m.TermDeliveryModule) },
  { path: 'formas-de-pagamento', loadChildren: () => import('./utils/pages/term-payments/term-payments.module').then(m => m.TermPaymentsModule) },
  { path: 'trocas-e-devolucoes', loadChildren: () => import('./utils/pages/term-exchanges/term-exchanges.module').then(m => m.TermExchangesModule) },
  { path: 'quem-somos', loadChildren: () => import('./utils/pages/who-we-are/who-we-are.module').then(m => m.WhoWeAreModule) },



  { path: 'nao-encontrado',  component: NotFoundComponent, },
  {
    path: ':slug',
    loadChildren: () =>
      import('./pages/store/product-detail/product-detail.module').then(
        m => m.ProductDetailModule,
      ),
  },
  {
    path: '**',
    component: NotFoundComponent,
  }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
