import { ResultRequest } from './../_models/ResultRequest';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/pages/auth/auth.service';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss']
})
export class InformationComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ResultRequest,
    private dialog: MatDialog,
    private dialogInformation: MatDialogRef<InformationComponent>,
    private router: Router,
    private authService: AuthService,
  ) { }


  ngOnInit(): void {
  }

  close() {
    if(this.data.error){
      this.dialogInformation.close();
    }else{
      this.dialog.closeAll();
    }
  }


}
