import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { StorageService } from 'src/app/core/storage/store.service';

@Component({
  selector: 'app-check-older',
  templateUrl: './check-older.component.html',
  styleUrls: ['./check-older.component.scss']
})
export class CheckOlderComponent implements OnInit {

  olderConfirm: boolean;
  olderRecuse: boolean;
  request: boolean = false;

  checkChange: boolean = false;

  constructor(
    private storage: StorageService,
    private dialogCheckOlder: MatDialogRef<CheckOlderComponent>,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.olderConfirm = true;
    this.olderRecuse = true;
  }

  close(){
    this.dialogCheckOlder.close();    
  }

  confirmOlder() {
    this.storage.saveSession('checkOlder', true);
    this.close();
  }
  
  // recuse() {
  //   this.storage.saveSession('checkOlder', false)
  //   // tslint:disable-next-line: one-variable-per-declaration
  //   const ua = navigator.userAgent,
  //   browser = /Edge\/\d+/.test(ua) ? 'ed' : /MSIE 9/.test(ua) ? 'ie9' : /MSIE 10/.test(ua) ? 'ie10' : /MSIE 11/.test(ua) ? 'ie11' : /MSIE\s\d/.test(ua) ? 'ie?' : /rv\:11/.test(ua) ? 'ie11' : /Firefox\W\d/.test(ua) ? 'ff' : /Chrom(e|ium)\W\d|CriOS\W\d/.test(ua) ? 'gc' : /\bSafari\W\d/.test(ua) ? 'sa' : /\bOpera\W\d/.test(ua) ? 'op' : /\bOPR\W\d/i.test(ua) ? 'op' : typeof MSPointerEvent !== 'undefined' ? 'ie?' : '',
  //   os = /Windows NT 10/.test(ua) ? "win10" : /Windows NT 6\.0/.test(ua) ? "winvista" : /Windows NT 6\.1/.test(ua) ? "win7" : /Windows NT 6\.\d/.test(ua) ? "win8" : /Windows NT 5\.1/.test(ua) ? "winxp" : /Windows NT [1-5]\./.test(ua) ? "winnt" : /Mac/.test(ua) ? "mac" : /Linux/.test(ua) ? "linux" : /X11/.test(ua) ? "nix" : "",
  //   touch = 'ontouchstart' in document.documentElement,
  //   mobile = /IEMobile|Windows Phone|Lumia/i.test(ua) ? 'w' : /iPhone|iP[oa]d/.test(ua) ? 'i' : /Android/.test(ua) ? 'a' : /BlackBerry|PlayBook|BB10/.test(ua) ? 'b' : /Mobile Safari/.test(ua) ? 's' : /webOS|Mobile|Tablet|Opera Mini|\bCrMo\/|Opera Mobi/i.test(ua) ? 1 : 0,
  //   tablet = /Tablet|iPad/i.test(ua);
  //   let homepageurl = browser == 'gc' ? 'https://www.google.com/_/chrome/newtab' : browser == 'op' ? 'about:speeddial' : browser=='sa' ? 'http://livepage.apple.com' : 'about:home'
  //   window.location.href = homepageurl;
  // }

  recuseOlder () {
    this.checkChange = !this.checkChange;
  }
  
}

