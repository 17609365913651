import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

constructor( private socket: Socket) { }


  socketChatId(chat_id){
    
    return this.socket.fromEvent<any>(`chats-${chat_id}-new-message`);
  }

  unsubscribeChat(chat_id){
    this.socket.removeListener(`chats-${chat_id}-new-message`, (res) => {
      
    })
    
  }

  disconnect(){
    this.socket.disconnect();
  }

  connect(){
    this.socket.connect();
  }

  printSocket(){
    
  }

}
